import React from "react"

import { StyledStory } from "./Story.styled"

import { Link } from "gatsby"

import Button from "../Button/Button.styled"

import icon1 from "../../assets/images/b1.svg"
import icon2 from "../../assets/images/b2.svg"
import icon3 from "../../assets/images/b3.svg"
import banner from "../../assets/images/banner2.jpeg"

const Story = () => {
  return (
    <StyledStory>
      <section>
        <h2>Dlaczego warto współpracować z MedInvest</h2>
        <div>
          <div className="story-left-column">
            <h3 className="story-first-h3">Współpraca z MedInvest</h3>
            <p>
              Dogłębnie rozumiemy jak funkcjonuje w Polsce opieka zdrowotna.
              Mamy kompleksową wiedzę na temat świadczeniodawców i obiektów
              opieki zdrowotnej - od Centrów Medycznych zlokalizowanych w
              biurowcach po stacjonarne i ambulatoryjne ośrodki chirurgiczne
              oraz szpitale
            </p>
            <p>
              Posiadamy rozwinięte, długoterminowe relacje ze wszystkimi grupami
              kapitałowymi, inwestycyjnymi, funduszami instytucjonalnymi typu
              private equality, funduszami emerytalnymi oraz setkami lekarzy, a
              także prywatnych nabywców
            </p>
            <p>
              Mamy do tego talent oraz rozległą wiedzę dotyczącą strategii
              finansowania i know-how niezbędny do obsługi najbardziej złożonych
              transakcji finansowych
            </p>
            <p>
              Wdrażamy innowacyjne narzędzia marketingowe, obsługę
              wyspecjalizowanych w branży medycznej prawników i rzeczoznawców
            </p>
            <p>
              Możesz skupić się na pacjentach i codziennej praktyce, podczas
              kiedy nasz wyspecjalizowany konsultant zadba dla Ciebie o dobór
              najkorzystniejszej oferty na rynku
            </p>
          </div>
          <span style={{ width: "50px", height: "50px" }} />
          <div className="story-right-column">
            <h3 className="story-second-h3">Działanie na własną rękę</h3>
            <p>
              Tracisz cenny czas na szukanie ofert
            </p>
            <p>
              Możesz zostać wykorzystnym przez nieuczciwych agentów i
              właścicieli
            </p>
            <p>Ryzykujesz przepłaceniem znacznych kwot</p>
            <p>
              Podejmujesz pod presją decyzje
            </p>
            <p>
              Brak Ci wsparcia doświadczonej osoby
            </p>
            <p>
              Tracisz okazję do zyskania dobrze rokujących kontaktów biznesowych
            </p>
          </div>
        </div>
      </section>
      <section>
        <img src={banner} alt="" loading="lazy" />
        <div>
          <h3>SPRAWDŹ ZREALIZOWANE PROJEKTY</h3>
          <p>Automatycznie przejdziesz do zakładki zrealizowane projekty</p>
          <Link style={{ textDecoration: "none" }} to="/aktualneoferty">
            <Button red>Przejdź</Button>
          </Link>
        </div>
      </section>
      <section>
        {/* <h1>ZAUFAJ PROFESJONALISTOM W BRANŻY MEDYCZNEJ</h1> */}
        <h2 style={{ width: "100%", textAlign: "center", maxWidth: "9000px" }}>
          Dlaczego warto wybrać właśnie nas?
        </h2>
        <div>
          <div>
            <img src={icon1} alt="" />
            <h3>Na sprzedaży placówek medycznych znamy się jak nikt</h3>
            <p>
              Proces wynajmu lub kupna placówki medycznej wymaga
              specjalistycznej wiedzy i doświadczenia. Ty nie musisz go
              posiadać, ale my znamy branżę medyczną od środka, dzięki czemu z
              sukcesem działamy w najlepszym interesie naszych klientów. Dołącz
              do nich i zyskaj spokój wyboru najkorzystniejszej oferty!
            </p>
          </div>
          <div>
            <img src={icon2} alt="" />
            <h3>Umożliwiamy Ci skupienie się na praktyce</h3>
            <p>
              Podczas kiedy my wyszukujemy i negocjujemy najkorzystniejsze opcje
              zakupu lub wynajmu, Ty możesz skupić się na tym, co potrafisz
              najlepiej. Poświęć czas i energię na doskonalenie zawodowe i stań
              się najbardziej cenionym specjalistą z branży!
            </p>
          </div>
          <div>
            <img src={icon3} alt="" />
            <h3>Do każdego klienta podchodzimy indywidulnie</h3>
            <p>
              Nasi doradcy w każde zlecenie wkładają maksimum zaangażowania.
              Tylko kompleksowe spojrzenie na daną transakcję, uwzględniające
              analizę potrzeb konkretnego klienta, daje gwarancję uzyskania dla
              niego najlepszych warunków. Skontaktuj się z nami i przekonaj, jak
              ważna jest pomoc doświadczonego pośrednika!
            </p>
          </div>
        </div>
      </section>
    </StyledStory>
  )
}

export default Story
