import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { StyledHeroImage } from "./HeroImage.styled"

import H1 from "../H1/H1"
import Button from "../Button/Button.styled"

import icon1 from "../../assets/images/a1.svg"
import icon2 from "../../assets/images/a2.svg"
import icon3 from "../../assets/images/a3.svg"

const HeroImage = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "assets/images/hero.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 3080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <StyledHeroImage>
          <BackgroundImage
            fluid={data.file.childImageSharp.fluid}
            alt="Medinvest"
            loading="eager"
          >
            <section id="hero-image-text-wrapper">
              <H1 white big>
                Sprawdź naszą ofertę <br /> placówek medycznych
              </H1>
              <div className="hero-image-button-and-info">
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <Button>Kontakt</Button>
                </Link>
                <p>Kompleksowa obsługa transakcji</p>
              </div>
            </section>
            <section id="hero-image-extra-info-top">
              <div>
                <div>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        marginRight: "12px",
                      }}
                    >
                      50+
                    </span>{" "}
                    Sprzedanych firm medycznych
                  </p>
                </div>
                <div>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        marginRight: "12px",
                      }}
                    >
                      250+
                    </span>{" "}
                    Zadowolonych klientów
                  </p>
                </div>
                <div>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        marginRight: "12px",
                      }}
                    >
                      10+
                    </span>{" "}
                    lat doświadczenia
                  </p>
                </div>
              </div>
            </section>
            <section id="hero-image-extra-info">
              <div>
                <div>
                  <img src={icon1} alt="" />
                  <p>
                    Dział sprzedaży posiada merytoryczne wsparcie lekarzy
                    niemalże każdej specjalizacji
                  </p>
                </div>
                <div>
                  <img src={icon2} alt="" />
                  <p>
                    Relacje ze wszystkimi głównymi grupami inwestycyjnymi, które
                    nabywają nieruchomości medyczne, w tym REIT,
                    instytucjonalnymi funduszami, funduszami emerytalnymi,
                    grupami kapitałowymi i prywatnymi nabywcami.
                  </p>
                </div>
                <div>
                  <img src={icon3} alt="" />
                  <p>
                    Rzeczoznawcy medyczni MedInvest wspierają indywidualnie
                    każdy projekt
                  </p>
                </div>
              </div>
            </section>
          </BackgroundImage>
        </StyledHeroImage>
      )}
    />
  )
}

export default HeroImage
