import styled from "styled-components"

export const StyledStory = styled.div`
  margin: 0 auto 0 auto;
  width: 100%;

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 400;
    font-size: 1.2rem;
  }

  h2 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.5rem;
    margin: 0 0 50px 0;
    text-align: center;

    ${({ theme }) => theme.media.tablet} {
      font-size: 2rem;
    }

    ${({ theme }) => theme.media.desktop} {
      font-size: 3rem;
      margin: 0 0 100px 0;
    }
  }

  section:nth-child(1) {
    margin: 0 20px 50px 20px;

    ${({ theme }) => theme.media.tablet} {
      margin: 0 30px 50px 20px;
    }

    ${({ theme }) => theme.media.desktop} {
      margin: 0 60px 100px 60px;
    }

    h2 {
      margin-left: auto;
      margin-right: auto;
      max-width: 1400px;
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-width: 1400px;
      margin: 0 auto;

      ${({ theme }) => theme.media.tablet} {
        flex-direction: row;
      }

      div {
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background-color: white;
        margin-bottom: 50px;

        h3 {
          margin: 0 0 0 0;
          padding: 24px 20px;
          padding-left: 50px;
          letter-spacing: 1px;
          font-size: 1.3rem;
          display: inline-block;
          font-weight: 400;

          ${({ theme }) => theme.media.tablet} {
            font-size: 1.4rem;
          }

          ${({ theme }) => theme.media.desktop} {
            font-size: 1.8rem;
          }
        }

        .story-first-h3 {
          position: relative;
          color: ${({ theme }) => theme.colors.secondary};

          ::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            background: ${({ theme }) => theme.colors.secondary};
            width: 40px;
            height: 4px;
          }
        }

        .story-second-h3 {
          position: relative;
          color: ${({ theme }) => theme.colors.primary};

          ::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            background: ${({ theme }) => theme.colors.primary};
            width: 40px;
            height: 4px;
          }
        }

        p {
          margin-bottom: 12px;
          padding: 0 20px 0 24px;
        }

        p:nth-child(2) {
          margin-top: 24px;
        }
      }

      div.story-left-column {
        p {
          position: relative;
          left: 20px;
          width: calc(100% - 20px);
          ::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            background: ${({ theme }) => theme.colors.secondary};
            width: 8px;
            height: 8px;
          }
        }
      }

      div.story-right-column {
        p {
          position: relative;
          left: 20px;
          width: calc(100% - 20px);
          ::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            background: ${({ theme }) => theme.colors.primary};
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  section:nth-child(2) {
    position: relative;
    margin-bottom: 50px;

    ${({ theme }) => theme.media.tablet} {
      margin-bottom: 100px;
    }

    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 99%;
      background-color: rgba(95, 162, 199, 0.7);
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 99%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      text-align: center;

      h3 {
        margin: 0 0 25px 0;
        color: #fff;
        font-size: 2rem;

        ${({ theme }) => theme.media.tablet} {
          font-size: 3rem;
        }
      }

      p {
        margin: 0 10px 25px 10px;
        color: #fff;
        font-size: 1.3rem;
      }
    }
  }

  section:nth-child(3) {
    padding: 0 20px 0 20px;
    background-color: white;

    ${({ theme }) => theme.media.tablet} {
      padding: 0 30px 0 20px;
    }

    ${({ theme }) => theme.media.desktop} {
      padding: 0 60px 0 60px;
    }

    h1 {
      margin: 0 0 8px 0;
    }
    h2 {
      max-width: 1400px;
      width: 100%;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;

      ${({ theme }) => theme.media.tablet} {
        flex-direction: row;
        margin-bottom: 100px;
      }
      div {
        padding: 10px 0;
        padding-bottom: 50px;
        flex: 1;
        flex-direction: column;
        margin: 0;

        ${({ theme }) => theme.media.tablet} {
          padding: 50px 10px;
        }

        ${({ theme }) => theme.media.desktop} {
          padding: 50px 40px;
        }

        img {
          height: 150px;
          margin-bottom: 24px;
        }

        h3 {
          font-weight: 400;
          font-size: 1.4rem;
          font-weight: 900;
          text-align: center;
          color: ${({ theme }) => theme.colors.primary};
          margin-top: 0;
        }
      }
    }
  }
`
