import styled from "styled-components"

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 25px;
  margin: 0 auto;
  border: ${({ red }) => (red ? "2px solid #CA797A " : "2px solid #5fa2c7")};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ red }) => (red ? "#CA797A" : "#5fa2c7")};
  font-size: 1.1rem;
  line-height: 18px;
  transition: all 0.3s;
  cursor: pointer;

  ${({ theme }) => theme.media.tablet} {
  }

  ${({ theme }) => theme.media.desktop} {
  }

  :hover {
    background: #cc6061;
    border: 2px solid #cc6061;
  }
`

export default Button
