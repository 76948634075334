import styled from "styled-components"

export const StyledHeroImage = styled.div`
  position: relative;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  margin: 0 0 60px 0;

  ${({ theme }) => theme.media.tablet} {
    margin: 0 0 80px 0;
  }

  ${({ theme }) => theme.media.desktop} {
    margin: 0 0 100px 0;
  }

  div {
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
  }

  #hero-image-text-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${({ theme }) => theme.media.tablet} {
      align-items: center;
      text-align: center;
    }

    h1 {
      max-width: 1000px;
      margin-bottom: 20px;
      margin-left: 20px;
      font-size: 2rem;
      letter-spacing: 2px;

      ${({ theme }) => theme.media.tablet} {
        margin-bottom: 30px;
        font-size: 2.5rem;
        margin-left: 0px;
      }

      ${({ theme }) => theme.media.desktop} {
        font-size: 3rem;
      }
    }

    .hero-image-button-and-info {
      position: static;
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 130px;
      margin-left: 20px;

      ${({ theme }) => theme.media.tablet} {
        flex-direction: row;
        margin-bottom: 100px;
        margin-left: 0;
        justify-content: center;
        align-items: center;
      }

      ${({ theme }) => theme.media.desktop} {
        margin-bottom: 200px;
      }
      a {
        cursor: default;
        button {
        }
      }

      p {
        margin-left: 8px;
        margin-bottom: 20px;
        color: ${({ theme }) => theme.colors.primary};

        ${({ theme }) => theme.media.tablet} {
          margin-bottom: 0;
        }
      }
    }
  }

  #hero-image-extra-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    display: none;
    justify-content: center;
    background-color: rgba(95, 162, 199, 0.7);
    padding: 80px 30px;


    ${({ theme }) => theme.media.tablet} {
        display: flex;
      }

    div {
      position: absolute;
      top: 0;
      height: 300px;
      max-width: 1400px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      ${({ theme }) => theme.media.tablet} {
        flex-direction: row;
      }
      div {
        position: relative;
        height: 300px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 10px 50px 10px;

        ${({ theme }) => theme.media.tablet} {
          padding: 30px 10px 30px 10px;
        }
        img {
          margin: 0 auto;
          padding-bottom: 20px;
          height: 80px;
        }
        p {
          text-align: center;
          color: ${({ theme }) => theme.colors.white};
          font-size: 1.25rem;
        }
      }
    }
  }

  #hero-image-extra-info-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: none;
    justify-content: center;
    background-color: rgba(95, 162, 199, 0.7);
    padding-bottom: 60px;


    ${({ theme }) => theme.media.tablet} {
        display: flex;
      }

    div {
      position: absolute;
      top: 0;
      height: 60px;
      max-width: 1400px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      ${({ theme }) => theme.media.tablet} {
        flex-direction: row;
      }
      div {
        position: relative;
        height: 60px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 10px 50px 10px;

        ${({ theme }) => theme.media.tablet} {
          padding: 30px 10px 30px 10px;
        }
        img {
          margin: 0 auto;
          padding-bottom: 20px;
          height: 80px;
        }
        p {
          text-align: center;
          color: ${({ theme }) => theme.colors.white};
          font-size: 1.25rem;
        }
      }
    }
  }
  }
`
