import styled from "styled-components"

export const StyledAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  top: 0;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  margin: 0 auto 50px auto;

  ${({ theme }) => theme.media.tablet} {
    margin-bottom: 100px;
  }
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 150px;
    flex-direction: row;
  }
  div {
    margin-bottom: 30px;

    ${({ theme }) => theme.media.desktop} {
      margin-bottom: 0;
    }

    h1 {
      color: ${({ theme }) => theme.colors.secondary};
    }
    h2 {
      padding-left: 65px;
      ::before {
        content: "";
        height: 4px;
        width: 48px;
        background: ${({ theme }) => theme.colors.primary};
        display: inline-block;
        position: absolute;
        left: 0;
        top: calc(50% - 2px);
      }
    }

    p {
      padding: 0 20px 0 68px;
      max-width: 500px;
      min-width: 300px;
      margin-bottom: 30px;

      ${({ theme }) => theme.media.tablet} {
        /* width: calc((100%) / 2); */
        padding: 0 0 0 69px;
        font-size: 17px;
      }

      ${({ theme }) => theme.media.desktop} {
        /* width: calc((100% - 20px) / 2); */
        font-size: 18px;
        line-height: 1.8;
      }
    }

    a {
      text-decoration: none;
      button {
        ${({ theme }) => theme.media.desktop} {
          margin-right: 0;
        }
      }
    }
  }

  .logoImage {
    width: auto;
    max-height: 150px;
    padding-left: 0px;

    ${({ theme }) => theme.media.tablet} {
      max-height: 150px;
    }

    ${({ theme }) => theme.media.desktop} {
      max-height: 150px;
      padding-left: 20px;
    }
  }
`
